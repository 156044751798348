var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "container",
    },
    [
      _c(
        "b-card",
        {
          staticClass: "border-0",
        },
        [
          _c(
            "p",
            {
              staticClass: "h5",
            },
            [_vm._v("Model")]
          ),
          _vm._l(_vm.radio.options, function (option, index) {
            return _c(
              "div",
              {
                staticClass: "d-inline mr-3",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.radio.picked,
                      expression: "radio.picked",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    id: `${option}_${index}`,
                  },
                  domProps: {
                    value: option,
                    checked: _vm._q(_vm.radio.picked, option),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.radio, "picked", option)
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "m-0 h6",
                    attrs: {
                      for: `${option}_${index}`,
                    },
                  },
                  [_vm._v(_vm._s(option))]
                ),
              ]
            )
          }),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-center",
            },
            [_c("h1", [_vm._v(_vm._s(_vm.profile.name))])]
          ),
          _c("full-calendar", {
            ref: "calendar",
            attrs: {
              options: _vm.calendarOptions,
            },
            scopedSlots: _vm._u([
              {
                key: "eventContent",
                fn: function (arg) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-center",
                      },
                      [_vm._v(_vm._s(arg.event.title))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center mt-3",
            },
            [
              _c(
                "div",
                [
                  _c(
                    "p",
                    {
                      staticClass: "h5",
                    },
                    [_vm._v("Model")]
                  ),
                  _vm._l(_vm.radio.options, function (option, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "d-inline mr-3",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.radio.picked,
                              expression: "radio.picked",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: `${option}_${index}`,
                          },
                          domProps: {
                            value: option,
                            checked: _vm._q(_vm.radio.picked, option),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.radio, "picked", option)
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "m-0 h6",
                            attrs: {
                              for: `${option}_${index}`,
                            },
                          },
                          [_vm._v(_vm._s(option))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("div", [_c("b-button", [_vm._v("Submit")])], 1),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }